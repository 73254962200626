<template>
  <div class="flex flex-col space-y-2 w-fit-content">
    <div
      class="flex flex-row items-center space-x-2 cursor-pointer"
    >
      <PrimarySwitch
        :value="value"
        :class="`${disabled ? 'pointer-events-none' : ''}`"
        @input="onChange(inputId, !value)"
      />
      <span
        class="text-space-dark-blue text-sm font-medium select-none"
        :class="`${disabled ? 'pointer-events-none' : ''}`"
        @click="() => onChange(inputId, !value)"
      >{{ label }}</span>
    </div>
    <div
      v-if="fieldStatus && (fieldStatus[inputId] !== '' || fieldStatus[inputId] !== undefined)"
      class="text-space-red text-xs"
    >
      {{ fieldStatus[inputId] }}
    </div>
  </div>
</template>
<script>
import PrimarySwitch from "@/components/switch/Switch";

export default {
  name: "SwitchField",
  components: {PrimarySwitch},
  props: {
    label: {
      type: String
    },
    value: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    inputId: {
      type: String
    },
    fieldStatus: {
      type: Object
    },
    onChange: {
      type: Function
    },
  },
};
</script>
