<template>
  <div class="game-visibility bg-white flex-col rounded-xl container p-8">
    <span class="text-2xl text-space-dark-blue font-raleway-extra-bold">{{ $t('games.game_configurations.game_visibility.title') }}</span>
    <ul class="list-none flex flex-wrap space-x-4 mt-6">
      <li
        v-for="(visibility, index) in visibilities"
        :key="index"
      >
        <CheckboxField
          :label="$t('games.game_configurations.game_visibility.options.' + visibility).toString()"
          :value="selectedVisibility === visibility"
          :on-change="() => onVisibilityChange(visibility)"
          :disabled="!(room.owner.id === user.id)"
        />
      </li>
    </ul>
  </div>
</template>
<script>
import {GAME_VISIBILITY_LIST} from "@/constants/games/GameVisibilityConstants";
import CheckboxField from "@/components/checkbox/CheckboxField";

export default {
  name: "GameVisibility",
  components: {CheckboxField},
  props: {
    room: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  emits: ['on-visibility-changed'],
  data: (instance) => {
    return {
      visibilities: GAME_VISIBILITY_LIST,
      selectedVisibility: instance.room.access
    };
  },
  watch: {
    room: function(newVal) {
      this.selectedVisibility = newVal.access;
    }
  },
  methods: {
    onVisibilityChange: function (visibility) {
      this.selectedVisibility = visibility;
      this.$emit('on-visibility-changed', {access: visibility});
    },
  }
};
</script>
<style scoped>
.game-visibility {
  grid-row: 3;
}
</style>
