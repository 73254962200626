<template>
  <div class="flex flex-col px-4 pt-3 space-y-2 relative">
    <GoBackButton
      container-classname="w-fit"
      :text-classname="'text-xl'"
      :to="previousPage"
    />
    <div
      v-if="room"
      class="page-room__configuration"
    >
      <GameParticipants :participants="room.users" :room="room"/>

      <div class="configurations">
        <div class="flex flex-row justify-between">
          <span class="geminis text-space-green text-3xl pb-1">Configurations</span>
          <span class="text-space-green text-xl pb-1">Code: {{ pin }}</span>
        </div>
        <div class="room__configuration">
          <GameTypeList
            :room="room"
            :user="user"
            @game-type-selected="onSettingsChanged"
          />
          <GameModes
            :room="room"
            :user="user"
            @bundle-selected="onSettingsChanged"
          />
          <GameSettings
            :room="room"
            :user="user"
            @settings-changed="onSettingsChanged"
          />
          <GameVisibility
            :room="room"
            :user="user"
            @on-visibility-changed="onSettingsChanged"
          />
          <RoomErrors
            v-if="errors"
            :errors="errors"
          />
        </div>
      </div>

      <div
        class="buttons flex flex-wrap  md:items-center flex-col-reverse md:flex-row md:justify-end gap-y-4 gap-x-2 md:gap-x-7 pt-7 pb-4"
      >
        <PrimaryButton
          :bg-color="'bg-space-dark-blue'"
          :text="quitGameLabel"
          :classes="'text-white h-12 hover:text-white'"
          @click="disconnectRoom"
        />
        <PrimaryButton
          :bg-color="'bg-white'"
          :text="sharedLink"
          :classes="'text-space-dark-blue h-12 hover:text-white'"
          @click="copyToClipboard"
        />
        <PrimaryButton
          v-if="owner && owner.id === user.id"
          :bg-color="'bg-space-green'"
          :text="$i18n.t('games.game_configurations.action_btn.start')"
          :classes="'text-space-dark-blue h-12 hover:text-white'"
          @click="launchGame"
        />
      </div>
    </div>
    <Modal v-if="showQuitModal" :value="showQuitModal" :show-close-icon="false">
      <template #title>
        <span class="text-space-dark-blue text-center text-2xl">{{$i18n.t('games.game_over.owner_leave_title')}}</span>
      </template>
      <template #content>
        <div class="mt-10">
          <p class="text-space-dark-blue">
            {{$i18n.t(modaleContent)}}
          </p>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";
import GameTypeList from "@/components/Games/gameType/GameTypeList.vue";
import GameParticipants from "@/components/Games/participants/GameParticipants.vue";
import GameModes from "@/components/Games/gameModes/GameModes.vue";
import GameSettings from "@/components/Games/gameSettings/GameSettings.vue";
import GameVisibility from "@/components/Games/gameVisibility/GameVisibility.vue";
import RoomConfigurationService from "../../services/roomConfigurationService";
import {ROUTE_GAME, ROUTE_HOME, ROUTE_ROOM_INVITATION, ROUTE_ROOM_TEAM} from "../../router";
import GoBackButton from "@/components/buttons/GoBackButton.vue";
import RoomErrors from "../../components/Games/errors/RoomErrors.vue";
import {UPDATE_ROOM_STATE} from "../../store/modules/userModule/actions";
import {GAME_IN_PROGRESS, GAME_TO_START} from "../../constants/games/GameStatusConstants";
import {GAME_TYPE_VS} from "@/constants/games/GameTypes";
import {debounce} from "vue-debounce";
import Modal from "../../components/modals/DefaultModal.vue";

export default {
  name: "RoomConfiguration",
  components: {
    Modal,
    RoomErrors,
    GoBackButton, GameVisibility, GameSettings, GameParticipants, GameTypeList, PrimaryButton, GameModes
  },
  computed: {
    quitGameLabel() {
      return this.owner.id === this.user.id ? this.$i18n.t('games.game_configurations.action_btn.owner_quit').toString()
        : this.$i18n.t('games.game_configurations.action_btn.quit').toString();
    }
  },
  data() {
    return {
      user: this.$store.getters.getUser,
      room: null,
      pin: this.$route.params.pin,
      socket: this.$socket.client,
      owner: null,
      sharedLink: this.$i18n.t('games.game_configurations.copy_link.share_link'),
      previousPage: ROUTE_HOME.path ?? null,
      errors: null,

      // Quit Modale
      showQuitModal: false,
      modaleContent: '',
      timeout: null
    };
  },
  async mounted() {
    // Get Room configuration
    this.room = await RoomConfigurationService.getRoom(this.pin);
    // Owner of the game
    this.owner = this.room.owner;
    // Get Users in Room
    this.room = {
      ...this.room,
      users: await RoomConfigurationService.getUsersInRoom(this.room.id)
    };
  },
  methods: {
    // When Owner Launch Game
    launchGame() {
      this.errors = null;
      const roomErrors = {
        minimumPlayers: RoomConfigurationService.checkMinimumPlayers(this.room.users),
        bundleNotChoosen: RoomConfigurationService.checkIfBundleChoosen(this.room.bundle)
      };

      if (RoomConfigurationService.hasErrors(roomErrors)) {
        this.errors = roomErrors;
        return;
      }

      RoomConfigurationService.launchGame(this.owner, this.room, this.pin, this.socket);
    },

    // Share the link
    copyToClipboard() {
      this.$clipboard(`${process.env.VUE_APP_URL}${ROUTE_ROOM_INVITATION.path}?pin=${this.pin}`);
      this.sharedLink = this.$i18n.t('games.game_configurations.copy_link.link_copied');
      debounce(() => {
        this.sharedLink = this.$i18n.t('games.game_configurations.copy_link.share_link');
      }, 5000)();
    },

    // Disconnect room
    disconnectRoom() {
      RoomConfigurationService.disconnectRoom(this.socket, this.user, this.pin);
    },

    onSettingsChanged(settings) {
      for (const [key, value] of Object.entries(settings)) {
        this.room[key] = value;
        if (key === 'gameType') {
          settings[key] = value.type;
        }
      }

      RoomConfigurationService.updateRoomConfiguration(
        {pin: this.pin},
        settings,
        this.socket
      );
    }
  },
  sockets: {
    async connectUserToRoom() {
      if (!this.room) return;
      this.room.users = await RoomConfigurationService.getUsersInRoom(this.room.id);
    },

    async disconnectUserFromRoom() {
      this.room.users = await RoomConfigurationService.getUsersInRoom(this.room.id);
    },

    async changeRoomConfiguration() {
      this.room = await RoomConfigurationService.getRoom(this.pin);
    },

    // Room has been launched
    launchGame(params) {
      // If vs, choose teams
      if (params.room.gameType.type === GAME_TYPE_VS.type) {
        this.$router.push({name: ROUTE_ROOM_TEAM.name});
        this.$store.dispatch(UPDATE_ROOM_STATE, GAME_TO_START);
      } else {
        // Else, go directly to game
        this.$store.dispatch(UPDATE_ROOM_STATE, GAME_IN_PROGRESS);
        this.$router.push({name: ROUTE_GAME.name});
      }
    },

    roomClosed(param) {
      this.showQuitModal = true;
      this.modaleContent = `games.game_over.${param.event}`;
      this.timeout = setTimeout(() => {
        this.$router.push({name: ROUTE_HOME.name});
      }, 30000);
    },

    roomReopened() {
      clearTimeout(this.timeout);
      this.showQuitModal = false;
    }
  }
};
</script>
<style scoped>

.buttons {
  grid-column: 1/4;
}

.page-room__configuration {
  display: grid;
  grid-template-columns: 1fr 4fr;
  column-gap: 20px;
  min-height: 95vh;
}

.room__configuration {
  display: grid;
  grid-template-columns: 2fr 1.5fr;
  grid-auto-rows: minmax(30px, auto);
  column-gap: 20px;
  row-gap: 20px;
  min-height: 95%;
  @apply mb-6;
}


@media screen and (max-width: 1024px) {
  .page-room__configuration {
    @apply flex flex-col items-center space-y-4 p-4;
  }

  .room__configuration {
    @apply flex flex-col items-center;
  }
}

.configurations {
  grid-row: 1;
  height: auto;
}
</style>
