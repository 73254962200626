<template>
  <label class="switch">
    <input
      :id="inputId"
      type="checkbox"
      :checked="checked"
      @click="toggle"
    >
    <span class="slider round" />
  </label>
</template>
<script>
export default {
  name: 'PrimarySwitch',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    inputId: {
      type: String
    }
  },
  emits: ['input'],
  data() {
    return {
      checked: this.value
    };
  },
  watch: {
    value: function (val) {
      this.checked = val;
    }
  },
  methods: {
    toggle(event) {
      event.preventDefault();
      this.$emit('input', this.checked);
    }
  }
};
</script>
<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  min-width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  @apply bg-space-dark-blue;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: .2s;
  transition: .2s;
}

.slider:before {
  @apply bg-white absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  -webkit-transition: .2s;
  transition: .2s;
}

input:checked + .slider {
  @apply bg-space-green;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

:not(.switch:has(input:checked)):hover > .slider {
  @apply bg-space-dark-blue-light;
}

.switch:has(input:checked):hover > .slider {
  @apply bg-space-green-light;
}
</style>
