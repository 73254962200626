import { render, staticRenderFns } from "./GameParticipants.vue?vue&type=template&id=dd55cbea&scoped=true&"
import script from "./GameParticipants.vue?vue&type=script&lang=js&"
export * from "./GameParticipants.vue?vue&type=script&lang=js&"
import style0 from "./GameParticipants.vue?vue&type=style&index=0&id=dd55cbea&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd55cbea",
  null
  
)

export default component.exports