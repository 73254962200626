<template>
  <div class="bg-white flex flex-col rounded-xl container p-8">
    <div class="flex items-center justify-between">
      <span class="text-2xl text-space-dark-blue font-raleway-extra-bold">{{
        $t('games.game_configurations.game_modes.title')
      }}</span>
      <div class="flex space-x-2 items-center">
        <IconButton
          icon="filter"
          @click="toggleFilter"
          icon-classname="!text-space-dark-blue"
          :tooltip-text="$t('common.filter')"
        />
        <SearchBar
          :value="search"
          @input="(value) => this.search = value"
          container-classname="h-10"
          input-wrapper-classname="h-10"
          :placeholder="$t('games.game_configurations.game_modes.search_placeholder').toString()"
          :override-loading="isLoading"
        />
      </div>

    </div>
    <Table
      :headers="headers"
      :data="bundles"
      table-container-classname="table-container"
      :pagination-values="paginationValues"
      :is-loading="isLoading"
      :selected-rows="room.bundle ? [room.bundle.id] : []"
      :loader-size="2"
    >
      <template #body>
        <BundlesTableBody
          :user="user"
          :bundles="bundles"
          :selected-rows="room.bundle ? [room.bundle.id] : []"
          :on-row-selected="onRowSelected"
          :actions="rowActions"
        />
      </template>
    </Table>
    <Drawer :open="openFilters"
            @close="handleDrawerClose"
            @change="handleFiltersChanged"
    >
      <template #content>
        <BundlesFiltersContent
          :filters="filters"
          @change="filters = $event"
        />
      </template>
    </Drawer>
    <Modal
      v-if="!!bundleToShow"
      :on-close="() => bundleToShow = null"
      :value="!!bundleToShow"
    >
      <template #content>
        <BundleDetailsModal
          :bundle="bundleToShow"
          :user="user"
        />
      </template>
    </Modal>
  </div>
</template>
<script>
import Table from "@/components/Table/Table";
import {GAME_MODES_TABLE_HEADERS} from "@/constants/games/GameModesConstants";
import SearchBar from "@/components/inputs/SearchBar";
import {debounce} from 'vue-debounce';
import BundleService from "@/services/bundles/BundleService";
import Drawer from "@/components/drawer/Drawer.vue";
import BundlesFiltersContent from "@/components/filters/bundles/BundlesFilterContent.vue";
import BundlesTableBody from "@/components/bundles/BundlesTableBody.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import Modal from "@/components/modals/DefaultModal.vue";
import BundleDetailsModal from "@/components/modals/bundleDetailsModal.vue";
import {createQueryParamString} from "@/utils/requests/FilterQueryParamGenerator";

export default {
  name: 'GameModes',
  components: {
    BundleDetailsModal,
    Modal,
    IconButton,
    BundlesTableBody,
    BundlesFiltersContent,
    Drawer,
    SearchBar,
    Table
  },
  props: {
    selectedGameMode: {
      type: Object
    },
    room: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    }
  },
  emits: ['bundle-selected'],
  data() {
    return {
      headers: GAME_MODES_TABLE_HEADERS,
      rowActions: {
        view: {
          label: this.$t('bundles.actions.view'),
          method: (bundle) => this.handleViewBundle(bundle)
        }
      },
      search: '',
      openFilters: false,
      paginationValues: {
        page: 1,
        pageSize: 10
      },
      isLoading: true,
      filters: [],
      bundles: [],
      searching: false,
      bundleToShow: null
    };
  },
  async mounted() {
    this.searchBundles();

    // add watch on search
    this.unwatch = this.$watch('search', debounce(function () {
      this.searchBundles();
    }, 500));
  },
  beforeDestroy() {
    this.unwatch();
  },
  methods: {
    searchBundles() {
      this.isLoading = true;
      BundleService.search({
        ...this.paginationValues,
        filters: createQueryParamString([
          ...this.filters,
          ...(this.search ? [{field: 'bundle.name', operator: 'iLike', value: this.search}] : [])
        ])
      }).then((response) => {
        this.bundles = response.results;
        this.paginationValues = {
          ...this.paginationValues,
          page: response.page,
          total: response.total,
          totalPages: response.totalPages,
          totalItemsInPage: response.totalItemsInPage
        };
      })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleDrawerClose() {
      this.openFilters = false;
    },
    handleFiltersChanged(filters) {
      this.filters = filters;
      this.handleDrawerClose();
      debounce(() => {
        this.searchBundles();
      }, 500)();
    },
    onRowSelected(bundle) {
      if (this.room.owner.id === this.user.id) {
        this.$emit('bundle-selected', {bundle: bundle});
      }
    },
    toggleFilter() {
      this.openFilters = !this.openFilters;
    },
    handleViewBundle(bundle) {
      this.bundleToShow = bundle;
    }
  }
};
</script>
<style scoped>
>>> .table-container {
  @apply mt-2;
  max-height: calc(100vh * 0.38);
}

.container {
  grid-row: 2/4;
}
</style>
