<template>
  <div class="flex flex-col space-y-2">
    <div
      class="flex flex-row items-center space-x-2 cursor-pointer"
      @click="() => onChange(inputId, !value)"
      :class="`${disabled ? 'pointer-events-none' : ''}`"
    >
      <PrimaryCheckbox
        :value="value"
        :disabled="disabled"
        @input="() => onChange(inputId, !value)"
        :checkbox-class="checkboxClass"
      />
      <span
        class=" text-sm font-medium select-none"
        :class="labelClass"
      >
        {{ label }}
      </span>
    </div>
    <div
      v-if="fieldStatus && (fieldStatus[inputId] !== '' || fieldStatus[inputId] !== undefined)"
      class="text-space-red text-xs"
    >
      {{ fieldStatus[inputId] }}
    </div>
  </div>
</template>
<script>

import PrimaryCheckbox from "@/components/checkbox/Checkbox";
export default {
  name: "CheckboxField",
  components: {PrimaryCheckbox},
  props: {
    label: {
      type: String
    },
    value: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    inputId: {
      type: String
    },
    fieldStatus: {
      type: Object
    },
    onChange: {
      type: Function
    },
    checkboxClass: {
      type: String
    },
    labelClass: {
      type: String,
      default: 'text-space-dark-blue'
    }
  }
};
</script>
