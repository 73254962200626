<template>
  <div class="flex flex-col justify-between items-center basis-1 sm:flex-1 space-y-6 w-full">
    <img
      :src="image"
      :alt="type.name"
      class="max-w-28 max-h-28"
      :title="isEditable ? '' : $i18n.t('games.game_configurations.soon_available')"
    >
    <PrimaryButton
      :bg-color="selected ? 'bg-space-green' :'bg-space-dark-blue'"
      :text="type.name"
      :classes="`text-white h-12 w-full md:w-4/5 lg:w-3/5 ${isEditable ? '' : 'opacity-50 pointer-events-none'}`"
      @click="$emit('game-type-selected', type)"
    />
  </div>
</template>
<script>
import PrimaryButton from "@/components/buttons/PrimaryButton";

export default {
  name: "GameType",
  components: {PrimaryButton},
  props: {
    type: {
      type: Object,
      required: true
    },
    itemsCount: {
      type: Number,
      default: 0
    },
    selected: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: false
    }
  },
  emits: ['game-type-selected'],
  data() {
    return {
      image: require(`@/assets/images/games/gameTypes/${this.type.image}`),
      containerWidth: `${100 / this.itemsCount}%`,
    };
  }
};
</script>
