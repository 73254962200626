<template>
  <tbody>
    <GameParticipantRow
      v-for="(participant, index) in participants"
      :key="index"
      :participant="participant"
      :is-owner="participant.id === room.owner.id"
    />
  </tbody>
</template>
<script>
import GameParticipantRow from "@/components/Games/participants/GameParticipantRow";

export default {
  name: 'GameParticipantsTableBody',
  components: {GameParticipantRow},
  props: {
    participants: {
      type: Array,
      required: true
    },
    room: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>
