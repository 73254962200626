<template>
  <tr class="font-bold text-xl font-raleway-extra-bold">
    <td class="flex">
      <span class="block truncate" :title="participant.pseudo">
        {{ participant.pseudo }}
      </span>
      <img v-if="isOwner" :src="ownerImage" alt="owner_image" :title="$i18n.t('games.game_configurations.game_participants.owner_img_title').toString()" class="h-6 w-6">
    </td>
    <td>
      <img :src="participant && participant.avatar ? participant.avatar : require('@/assets/images/btn-astro.svg')" alt="astro" class="h-9 w-9 object-cover object-center rounded-full float-right">
    </td>
  </tr>
</template>
<script>
// import UserAvatar from "@/components/avatar/UserAvatar";
export default {
  name: 'GameParticipantRow',
  // components: {UserAvatar},
  props: {
    participant: {
      type: Object,
      required: true
    },
    isOwner: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  computed: {
    ownerImage() {
      return this.isOwner ? require('@/assets/images/meteore.png') : null;
    }
  }
};
</script>
<style scoped>

</style>
