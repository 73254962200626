<template>
  <div class="bg-white rounded-xl flex flex-col game-type p-6 overflow-y-auto container min-w-full">
    <span class="text-2xl text-space-dark-blue font-raleway-extra-bold">{{ $t('games.game_configurations.game_types.title') }}</span>
    <div class="flex w-full pt-4 flex-wrap space-y-6 space-x-4">
      <GameType
        v-for="(type, index) in types"
        :key="index"
        :type="type"
        :items-count="types.length"
        :selected="room.gameType === type"
        :is-editable="room.owner.id === user.id && type.type !== GAME_TYPE_SOLO.type"
        @game-type-selected="(type) => $emit('game-type-selected', {gameType: type})"
      />
    </div>
  </div>
</template>
<script>
import GameType from "@/components/Games/gameType/GameType";
import {GAME_TYPE_SOLO, GAME_TYPES} from "@/constants/games/GameTypes";

export default {
  name: "GameTypeList",
  computed: {
    GAME_TYPE_SOLO() {
      return GAME_TYPE_SOLO;
    }
  },
  components: { GameType },
  props: {
    room: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  emits: ['game-type-selected'],
  data: () => {
    return {
      types: GAME_TYPES
    };
  }
};
</script>

<style scoped>
  .container {
    /*max-height: 20vh;*/
    /*min-height: 20vh;*/
    grid-column: 1/3;
    grid-row: 1;
  }
</style>
