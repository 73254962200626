<template>
  <div
    v-if="errors"
    class="room-errors bg-red-600 rounded-xl flex flex-col overflow-y-auto p-4 space-y-4"
  >
    <div
      v-for="(value, name) of errors"
      :key="name"
      class="inline-flex"
    >
      <div v-if="value">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="28"
          width="28"
          viewBox="0 0 50 50"
          fill="white"
        ><path d="M4.7 42.15q-1 0-1.5-.85t0-1.7L22.5 6.25q.55-.85 1.5-.85t1.5.85L44.8 39.6q.5.85 0 1.7t-1.5.85ZM24.2 19.5q-.65 0-1.075.425Q22.7 20.35 22.7 21v8.1q0 .65.425 1.075.425.425 1.075.425.65 0 1.075-.425.425-.425.425-1.075V21q0-.65-.425-1.075-.425-.425-1.075-.425Zm0 16.65q.65 0 1.1-.475.45-.475.45-1.125t-.45-1.075q-.45-.425-1.1-.425-.65 0-1.1.45-.45.45-.45 1.1 0 .65.45 1.1.45.45 1.1.45Z" /></svg>
      </div>
      <span class="pl-4">{{ value }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "RoomErrors",
  props: {
    errors: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
.room-errors {
  grid-row: 4;
  grid-column: 1/3;
}
</style>
