<template>
  <div class="participants flex flex-col w-full">
    <span class="geminis text-space-green text-3xl pb-1">Participants</span>
    <div class="bg-white rounded-xl h-full">
      <Table
        :headers="headers"
        tableContainerClassname="!px-0 !mx-0"
      >
        <template #colGroup>
          <colgroup>
            <col class="w-4/5 md:w-7/12 xl:w-3/4">
            <col class="w-1/5 md:w-4/12 xl:w-1/4">
          </colgroup>
        </template>
        <template #body>
          <GameParticipantsTableBody :participants="participants"
                                     :room="room"/>
        </template>
      </Table>
    </div>
  </div>
</template>
<script>
import Table from "@/components/Table/Table";
import GameParticipantsTableBody from "@/components/Games/participants/GameParticipantsTableBody";

export default {
  name: "GameParticipants",
  components: {GameParticipantsTableBody, Table},
  props: {
    participants: {
      type: Array,
      required: true
    },
    room: {
      type: Object,
      required: true
    }
  },
  computed: {
    headers() {
      return [
        {
          name: '',
        },
        {
          name: '',
          classname: '',
        },
        {
          name: '',
          classname: '',
        },
      ];
    }
  }
};
</script>
<style scoped>
>>> .table-container{
  @apply  w-full rounded-xl pt-4;
  min-height: 100%;
}

>>> .table-wrapper {
  @apply mx-4;
}

.participants{
  grid-row: 1;
}
</style>
