import { render, staticRenderFns } from "./GameModes.vue?vue&type=template&id=00218d4a&scoped=true&"
import script from "./GameModes.vue?vue&type=script&lang=js&"
export * from "./GameModes.vue?vue&type=script&lang=js&"
import style0 from "./GameModes.vue?vue&type=style&index=0&id=00218d4a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00218d4a",
  null
  
)

export default component.exports