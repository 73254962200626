<template>
  <div
    v-if="settings"
    class="game-settings bg-white rounded-xl flex flex-col overflow-y-auto p-8 space-y-4 w-full"
  >
    <span class="text-2xl text-space-dark-blue font-raleway-extra-bold">{{ $t('games.game_configurations.game_settings.title') }}</span>
    <SwitchField
      v-for="(settingKey, index) in Object.keys(settings)"
      :key="index"
      :label="$t('games.game_configurations.game_settings.' + settingKey).toString()"
      :input-id="settingKey"
      :value="settings[settingKey]"
      :on-change="handleChange"
      :disabled="room.owner.id !== user.id"
    />
  </div>
</template>
<script>
import SwitchField from "@/components/switch/SwitchField";

export default {
  components: {SwitchField},
  props: {
    room: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  emits: ['settings-changed'],
  data: (instance) => {
    return {
      settings: instance.room.gameSettings
    };
  },
  watch: {
    room: function (newVal) {
      this.settings = newVal.gameSettings;
    }
  },
  methods: {
    handleChange(setting, value) {
      this.settings[setting] = value;
      this.$emit('settings-changed', this.settings);
    }
  }
};
</script>
<style scoped>
.game-settings{
  grid-row: 2;
}
</style>
