import i18n from "@/i18n/i18n";
import {BUNDLE_TABLE_HEADER_ACTIONS} from "@/constants/bundles/BundlesConstants";

export const GAME_MODES_TABLE_HEADERS = [
  {
    name: i18n.t('games.game_configurations.game_modes.exercices_count').toString(),
    value: 'exercises_count',
  },
  {
    name: i18n.t('games.game_configurations.game_modes.game_name').toString(),
    value: 'name',
  },
  {
    name: i18n.t('games.game_configurations.game_modes.languages').toString(),
    value: 'language',
  },
  {
    name: i18n.t('games.game_configurations.game_modes.creator').toString(),
    value: 'creator',
  },
  BUNDLE_TABLE_HEADER_ACTIONS,
];
